import React, { useEffect, useRef } from "react"
import useLocalStorageState from 'use-local-storage-state'
import { Navigation } from "../components/03Organisms/Navigation"
import { MenuItems } from "../components/03Organisms/NavigationMenuItems"
import SectionAll from "../components/04Pages/SectionAll"
import Layout from "../components/layout"
import { checkIsIOS } from "../components/_helpers"
import useWindowSize from "../hooks/useWindowSize"
import { useIsMount } from "./../hooks/useIsMount"


const IndexPage = props => {
  // gsap.registerPlugin(ScrollTrigger);

  const parallaxRef = useRef()
  const size = useWindowSize()
  const isMount = useIsMount()

  const isIOS = checkIsIOS()
  // const isMobile = size.width < 641
  const [mobileMenu, setMobileMenu] = useLocalStorageState(false)


  let y = 0
  // let style = { "--offset": y };

  

  useEffect(() => {
    // console.log("ref", parallaxRef);

    let locomotiveScroll = window.scroll
    locomotiveScroll.update();
    
    let scroll = {cache: 0, current: 0};

    locomotiveScroll.on("scroll", func => {
      // Update `data-direction` with scroll direction.
      // document.documentElement.setAttribute("data-direction", func.direction);
      // setScrollY((func.scroll.y)/100);
      // setScrollY(func.scroll.y);
    
      scroll.current = func.scroll.y;
      scroll.cache = scroll.current;
    });

    //   y = func.scroll.y
    //   // y=console.log("funct", func.scroll.y);
    //   // console.log("y", y);
    //   // scrollT = func.scrollY;
    // })
    // // scrollT = window.scroll.scroll.instance.scroll.y;
    // // locomotiveScroll.update();

    // // typeof window !== "undefined" && window.scroll.update();


    // locomotiveScroll.on("scroll", ScrollTrigger.update);
    // ScrollTrigger.scrollerProxy(".scroll-container", {
    //   scrollTop(value) {
    //     return arguments.length ? locomotiveScroll.scrollTo(value, 0, 0) : locomotiveScroll.scroll.instance.scroll.y;
    //   }, // we don't have to define a scrollLeft because we're only scrolling vertically.
    //   getBoundingClientRect() {
    //     return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
    //   },
    //   // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
    //   pinType: document.querySelector(".scroll-container").style.transform ? "transform" : "fixed"
    // });

    // gsap.set("#home, #when", {svgOrigin:"200 200"})

    // // let tl = gsap.timeline({defaults:{ease:"none"}})
    // //   .to("#home", {rotation:360, duration:3})
    // //   .to("#when", {rotation:30, duration:3}, 0)
    // let tl = gsap.timeline({defaults:{ease:"none"}})
    //   .to("#home", {opacity:360, duration:3})
    //   .to("#when", {opacity:30, duration:3}, 0)

    // ScrollTrigger.create({
    //   trigger:"body",
    //   start:"50% 50%",
    //   end:"+=300",
    //   scroller:".scroll-container",
    //   animation:tl,
    //   scrub:true,
    //   pin:true,
    //   // onUpdate: self => console.log("progress:", self.progress),
    //   onEnter: ({progress, direction, isActive}) => console.log(progress, direction, isActive)
    // })

    // // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
    // ScrollTrigger.addEventListener("refresh", () => locomotiveScroll.update());
    

    // // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
    // ScrollTrigger.refresh();


  })

  

  // let scrollT = 0

  // console.log("mobile menu", mobileMenu)
  // console.log("mobile menu", mobileMenu)
  // console.log("window y", scrollY)
  // console.log("y", y)
  // console.log("menu open", mobileMenu)

  return (
    <Layout>
      <div
        ref={parallaxRef}
        data-scroll-container
        className="scroll-container"
        id="yah"
      >
        {/* <div className="dots dots1"></div> */}
        {/* <div className="dots dots2"></div> */}

        <span
          className={`mobile-menu-toggle ${mobileMenu ? "open" : ""}`}
          onClick={() => setMobileMenu(!mobileMenu)}
        >
          {mobileMenu ? (
            <b
              className="las la-times"
              onClick={() => setMobileMenu(!mobileMenu)}
            >✕</b>
            // <i
            //   className="las la-times"
            //   onClick={() => setMobileMenu(!mobileMenu)}
            // ></i>
          ) : (
            "Menu"
          )}
        </span>

        <div className={`mobile-menu show`}>
          <div className={`navigation ${mobileMenu ? "expanded" : ""}`}>
            <div
              key="tilty-mobile"
              className={`menu-items ${mobileMenu ? "expanded" : ""}`}
            >
              <MenuItems />
            </div>
          </div>
        </div>

        <Navigation mobileMenu={mobileMenu} />
        <SectionAll mobileMenu={mobileMenu} size={size} />
      </div>
    </Layout>
  )
}

export default IndexPage
